import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';
// Define interfaces for the API response structure
const apiUrl = config.apiUrl;

interface Status {
  value: number;
  status: string;
}

interface Comment {
  date: string;
  name: string;
  topic: string;
  message: string;
  profile: string;
  userSub: string;
}

interface Buyer {
  name: string;
}

interface Style {
  style_no: string;
  style_name: string;
  id: string;
  buyer: Buyer;
}

interface Employee {
  name: string | null;
}

interface Order {
  id: string;
  approved_by: string | null;
  created_at: string;
  started_at: string;
  style: Style;
  employee: Employee | null;
}

interface ProductDetails {
  id: string;
  name: string;
  description: string | null;
  units: string;
  skw: string;
  type: string | null;
  category: string | null;
}

interface TrimData {
  id: string;
  product_id: string;
  actual_quantity: number | null;
  expected_quantity: number;
  delivered_quantity: number | null;
  order_id: string;
  store_type: number;
  done: string | null;
  status: Status[] | null;
  comments: Comment[] | null;
  created_at: string;
  updated_at: string;
  order: Order;
  product: ProductDetails;
}

interface TrimState {
  trims: TrimData[];
  loading: boolean;
  error: string | null;
}

const initialState: TrimState = {
  trims: [],
  loading: false,
  error: null,
};

// Thunk to fetch trims by item.id and storeType
export const fetchTrimsByStore = createAsyncThunk(
  'trims/fetchTrimData',
  async ({ itemId, storeType }: { itemId: string; storeType: number }) => {
    const response = await axios.get(`${apiUrl}/api/v1/store-order/order/${itemId}/store/1`);
    console.log(response);
    
    return response.data;  // Directly return the array of trim data as it's not nested inside any other field
  }
);

const trimSlice = createSlice({
  name: 'trims',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrimsByStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTrimsByStore.fulfilled, (state, action) => {
        state.loading = false;
        state.trims = action.payload;  // The API directly returns the trims array
      })
      .addCase(fetchTrimsByStore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch trims';
      });
  },
});

export default trimSlice.reducer;
