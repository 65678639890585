import { signIn } from "@aws-amplify/auth";
import React, { useState, FormEvent } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import bgImage from "../assets/bg-signin-img.jpg"

interface SignInProps {
  onSignIn: () => void;
}

const SignIn: React.FC<SignInProps> = ({ onSignIn }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const response = await signIn({
        username: email,
        password: password,
      });
      onSignIn();
    } catch (err) {
      console.error(err);
      setError("Failed to sign in. Please check your credentials and try again.");
    }
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-gray-100 p-4 bg-cover bg-center relative"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="absolute inset-0 bg-black opacity-30 blur-md"></div>
      <div className="relative w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 p-6 space-y-4 bg-white rounded-lg shadow-md">
        <h2 className="text-xl font-bold text-center">
          <div className="text-2xl mb-2 font-bold">SK-Export</div>Sign In
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mx-2">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email:
            </label>
            <input
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
          </div>
          <div className="mx-2">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password:
            </label>
            <div className="flex items-center mt-1 border border-gray-300 rounded-md shadow-sm focus-within:ring-indigo-500 focus-within:border-indigo-500">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border-none focus:ring-0 focus:border-none"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="px-3 py-2 text-gray-500 focus:outline-none"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="mx-2">
            <button
              type="submit"
              className="w-full px-4 py-2 text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
        </form>
        {error && <div className="text-red-500 text-sm text-center mt-2">{error}</div>}
      </div>
    </div>
  );
};

export default SignIn;
