import React, { useEffect, useState, lazy, Suspense } from 'react';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import { fetchFabricByStyle } from '../redux/fabricSlice';
import config from '../config';
import Loader from '../components/Loader';
import { fetchProductsByStore } from '../redux/productSlice'; // Import product slice
import { fetchTrimsByStore } from '../redux/trimSlice'; // Import trim slice
// Lazy load components
const ProductStore = lazy(() => import('../components/ProductStore'));
const TrimsStore = lazy(() => import('../components/TrimsStore'));
const StyleBasicInfo = lazy(() => import('../components/StyleBasicInfo'));
const ProcessFlowComponent = lazy(() => import('../components/ProcessFlowComponent'));
const ChatSection = lazy(() => import('../components/ChatSection'));
const ChatDetails = lazy(() => import('../components/ChatDetails'));
const Fabric = lazy(() => import('../components/Fabric'));
const DataNotFound = lazy(() => import('../components/DataNotFound')); // Import DataNotFound component


const apiUrl = config.apiUrl;

interface StateData {
    id: string;
    tenant_id: number;
    state_name: number;
    status: number;
    approved_by: string | null;
    assigned_to: string;
    start: string;
    end: string | null;
    created_at: string | null;
    updated_at: string | null;
    priority: string | null;
    approve_date: string | null;
    order_id: string;
}

interface FabricProps {
    id?: string;
    crm_id?: number;
    crm_name: string | null;
    tenant_id?: number;
    style_id: string | null;
    fabric_name: string | null;
    cad_avg?: number;
    man_avg?: number;
    grain: string | null;
    finished_width?: number;
    man_width?: number;
    created_at?: string | null; // Updated to allow null
    updated_at?: string | null;
}

// Interface for the status array
interface Status {
    value: number;
    status: string;
}

// Interface for comments in the response
interface Comment {
    date: string;
    name: string;
    topic: string;
    message: string;
    profile: string;
    userSub: string;
}

// Interface for the buyer information
interface Buyer {
    name: string;
}

// Interface for the style inside order
interface Style {
    style_no: string;
    style_name: string;
    id: string;
    buyer: Buyer;
}

// Interface for the employee inside order
interface Employee {
    name: string;
}

// Interface for the order inside the response
interface Order {
    id: string;
    approved_by: string;
    created_at: string;
    started_at: string;
    style: Style;
    employee: Employee;
}

// Interface for the product inside the response
interface ProductDetails {
    id: string;
    name: string;
    description: string | null;
    units: string;
    skw: string;
    type: string | null;
    category: string | null;
}

// Interface for each product entry in the response
interface ProductData {
    id: string;
    product_id: string;
    actual_quantity: number | null;
    expected_quantity: number;
    delivered_quantity: number | null;
    order_id: string;
    store_type: number;
    done: string | null;
    status: Status[] | null;
    comments: Comment[] | null;
    created_at: string;
    updated_at: string;
    order: Order;
    product: ProductDetails;
}

// Main response interface for an array of products
interface ProductApiResponse {
    products: ProductData[];
}


const Order: React.FC = () => {
    const item = useSelector((state: RootState) => state.orders.currentOrder); // Access currentOrder from Redux
    const [activeState, setActiveState] = useState<string>('pattern');
    const [error, setError] = useState<boolean>(false); // State for handling errors
    const dispatch = useDispatch<AppDispatch>();
    const fabrics = useSelector((state: RootState) => state.fabrics.fabrics);
    const loading = useSelector((state: RootState) => state.fabrics.loading);
    const productData = useSelector((state: RootState) => state.products.products); // Get product data from Redux
    const loadingProducts = useSelector((state: RootState) => state.products.loading);

    const trimData = useSelector((state: RootState) => state.trims.trims); // Get trim data from Redux
    const loadingTrims = useSelector((state: RootState) => state.trims.loading);
    const [stateColor, setStateColor] = useState<{ [key: string]: string }>({});
    const [userLogged, setUserLogged] = useState<String>('');
    const [userLoggedProfile, setUserLoggedProfile] = useState<String>('');
    const [userSub, setUsersub] = useState<String>('');
    const [orderStates, setOrderStates] = useState<[]>([]);


    const style_id = item?.style.id;
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const fetchedData = await fetchAuthSession();
                console.log(fetchedData);

                const loggedUserName = fetchedData.tokens?.idToken?.payload['cognito:username']
                const loggedUserProfile = fetchedData.tokens?.idToken?.payload['profile'];
                const sub = fetchedData.userSub;

                setUsersub(String(sub));
                setUserLogged(String(loggedUserName));
                setUserLoggedProfile(String(loggedUserProfile)); // Simulating user role, adjust as needed

            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchUserData();
    }, []);

    // useEffect(() => {
    //     if (item?.id) {
    //         // Fetch product data for storeType 0 (product store)
    //         dispatch(fetchProductsByStore({ itemId: item.id, storeType: 0 }));

    //         // Fetch trim data for storeType 1 (trim store)
    //         dispatch(fetchTrimsByStore({ itemId: item.id, storeType: 1 }));
    //     }
    // }, [dispatch, item]);

    useEffect(() => {
        if (item?.id) {
          // Initial fetch on mount
          dispatch(fetchProductsByStore({ itemId: item.id, storeType: 0 }));
          dispatch(fetchTrimsByStore({ itemId: item.id, storeType: 1 }));
    
          // Set up polling every 10 seconds
          const intervalId = setInterval(() => {
            dispatch(fetchProductsByStore({ itemId: item.id, storeType: 0 }));
            dispatch(fetchTrimsByStore({ itemId: item.id, storeType: 1 }));
            console.log("** in polling for product and trims **")
          }, 10000); // 10 seconds
    
          // Clear interval on unmount to prevent memory leaks
          return () => clearInterval(intervalId);
        }
      }, [dispatch, item]);

    useEffect(() => {
        const fetchState = async () => {
            if (!item) return; // Make sure item is not null

            // Mapping activeState to state_name
            const stageNameMapping: { [key: number]: string } = {
                1: 'pattern',
                2: 'cutting',
                3: 'sampling',
                4: 'finishing',
                5: 'email',
                6: 'sample closing'
            };

            try {
                const response = await fetch(`${apiUrl}/api/v1/state/order/${item.id}`);
                if (!response.ok) {
                    if (response.status === 404) {
                        setError(true); // Set error to true if 404
                    }
                    return;
                }
                const stateData: StateData[] = await response.json();


                const highestStateObject = stateData.reduce((max, current) => {
                    return (current.state_name > max.state_name) ? current : max;
                }, stateData[0]);

                let state_val = highestStateObject.state_name;
                if (highestStateObject.approve_date && highestStateObject.state_name < 6) {
                    state_val++;
                }
                const stateName = stageNameMapping[state_val] || 'pattern';
                setActiveState(stateName)



                // Set all states up to highestStateObject.state_name to green, except the highest which needs conditional coloring
                const newStateColor: { [key: string]: string } = {};
                for (let i = 1; i < highestStateObject.state_name; i++) {
                    const stageName = stageNameMapping[i];
                    newStateColor[stageName] = 'green';
                }

                // Conditionally set highestStateObject.state_name color
                const highestStateName = stageNameMapping[highestStateObject.state_name];

                // Check the conditions for the highest state:
                // If start is not null and approve_date is null, set to orange
                // If approve_date is not null, set to green
                if (highestStateObject.start !== null && (!highestStateObject.approve_date || highestStateObject.approve_date === '')) {
                    newStateColor[highestStateName] = 'orange'; // Set to orange if start is not null and approve_date is null or empty
                } else if (highestStateObject.approve_date !== null && highestStateObject.approve_date !== '') {
                    newStateColor[highestStateName] = 'green'; // Set to green if approve_date is not null or empty
                }

                setStateColor(newStateColor); // Update stateColor

            } catch (error) {
                console.error('Error fetching employees:', error);

            }
        };

        fetchState();
    }, [item]); // Depend on item to re-fetch when it changes

    

    useEffect(() => {
        if (style_id) { // Check if style_id is not null or undefined
            dispatch(fetchFabricByStyle(style_id)).catch(err => {
                console.error('Error fetching fabrics:', err);
                setError(true); // Set error if fetching fabrics fails
            });
        }
    }, [dispatch, style_id]);

    useEffect(() => {
        const fetchOrderStates = async () => {
            if (!item) return; // Ensure that item exists

            try {
                const response = await fetch(`${apiUrl}/api/v1/state/order/${item.id}`);
                const data = await response.json();
                console.log(data);

                if (!response.ok) {
                    throw new Error('Failed to fetch order states');
                }

                // const data = await response.json();
                setOrderStates(data);  // Store API response data in state
                console.log("order STate : ", orderStates);

            } catch (error) {
                console.error('Error fetching order states:', error);
            }
        };

        fetchOrderStates();

        // Set interval to fetch every 10 seconds
        const intervalId = setInterval(() => {
            fetchOrderStates();
        }, 10000); // 10 seconds = 10000 ms

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);

        // fetchOrderStates();
    }, [activeState]); // Fetch when `item` changes

    console.log("orderState : ", orderStates);


    


    // Show the "Data Not Found" page if error state is true
    if (error) {
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <DataNotFound />
            </Suspense>
        );
    }

    if (loading) return <div><Loader /></div>;

    const handleStageChange = (stage: string) => {
        setActiveState(stage);
    };

    if (!item) {
        return <div>Loading or no order selected...</div>;
    }



    const handleStateAccept = (stateName: string) => {
        setStateColor((prevColors) => {
            const newColors = { ...prevColors };

            // Mark all states up to and including the accepted state
            const stageNameMapping: { [key: string]: number } = {
                'pattern': 1,
                'cutting': 2,
                'sampling': 3,
                'finishing': 4,
                'email': 5,
                'sample closing': 6,
            };

            const acceptedStateNumber = stageNameMapping[stateName];

            // Set all states before the accepted state to green
            Object.keys(stageNameMapping).forEach(stage => {
                if (stageNameMapping[stage] < acceptedStateNumber) {
                    newColors[stage] = 'green'; // Set states before the highest as green
                }
            });

            // Set the accepted state (highest state) to green
            if (acceptedStateNumber === stageNameMapping[stateName]) {
                newColors[stateName] = 'green'; // Once accepted, the highest state becomes green
            }

            return newColors;
        });
    };




    console.log("********", productData);

    return (
        <div className='flex flex-col space-y-8'>
            <Suspense fallback={<p>Loading StyleBasicInfo...</p>}>
                <StyleBasicInfo />
            </Suspense>

            <Suspense fallback={<p>Loading Fabric...</p>}>
                <Fabric fabrics={fabrics} />
            </Suspense>
            <Suspense fallback={<p>Loading Product and Trims Store...</p>}>
                <div className="flex justify-around items-start border-2 border-gray-200 shadow-md bg-white mx-4 rounded-2xl">
                    <ProductStore products={productData} />
                    <TrimsStore trims = {trimData}/>
                </div>
            </Suspense>

            <Suspense fallback={<p>Loading ProcessFlowComponent...</p>}>
                <ProcessFlowComponent activeState={activeState} onStageChange={handleStageChange} stateColor={stateColor} />
            </Suspense>

            <div className="flex flex-col border-2 border-gray-400 items-center justify-center bg-white rounded-lg p-4 mx-4">
                <Suspense fallback={<p>Loading ChatDetails...</p>}>
                    <ChatDetails activeState={activeState} fabrics={fabrics} onAccept={handleStateAccept} userSub={userSub} profile={userLoggedProfile} name={userLogged} orderState={orderStates} />
                </Suspense>

                {/* <Suspense fallback={<p>Loading ChatSection...</p>}>
                    <ChatSection />
                </Suspense> */}
            </div>
        </div>
    );
};

export default Order;
