import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addShift  } from '../redux/shiftsSlice';
import { AppDispatch } from '../redux/store';
// Helper function to convert 24-hour time to 12-hour format with AM/PM
const convertTo12HourFormat = (time24: string): string => {
  const [hours, minutes] = time24.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight and use modulus for 12-hour format
  return `${hours12}:${minutes < 10 ? '0' + minutes : minutes} ${period}`;
};

const AddShift: React.FC<{ addShifts: boolean, department: number, onClose: () => void }> = ({ addShifts, department, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  
  const [fromHour, setFromHour] = useState('00');
  const [fromMinute, setFromMinute] = useState('00');
  const [fromPeriod, setFromPeriod] = useState('AM');

  const [toHour, setToHour] = useState('00');
  const [toMinute, setToMinute] = useState('00');
  const [toPeriod, setToPeriod] = useState('PM');

  const [expectedEfficiency, setExpectedEfficiency] = useState('');

  if (!addShifts) return null;

  // Function to combine hour, minute, and period into 24-hour time format for conversion
  const to24HourFormat = (hour: string, minute: string, period: string): string => {
    let hours24 = parseInt(hour);
    if (period === 'PM' && hours24 !== 12) {
      hours24 += 12;
    } else if (period === 'AM' && hours24 === 12) {
      hours24 = 0;
    }
    return `${hours24 < 10 ? '0' + hours24 : hours24}:${minute}`;
  };

  const handleAddShift = () => {
    // Convert input times to 24-hour format before sending to the Redux action
    const fromTime24 = to24HourFormat(fromHour, fromMinute, fromPeriod);
    const toTime24 = to24HourFormat(toHour, toMinute, toPeriod);

    // Convert the 24-hour time to 12-hour format with AM/PM
    const fromTimeFormatted = convertTo12HourFormat(fromTime24);
    const toTimeFormatted = convertTo12HourFormat(toTime24);

    // Prepare payload for the new shift
    const newShift = {
      department,
      shift: `${fromTimeFormatted} to ${toTimeFormatted}`, // Shift time in 12-hour format
      expected_avg: parseFloat(expectedEfficiency), // Convert expected efficiency to a number
    };

    // Dispatch the action to add the shift
    dispatch(addShift(newShift));

    // Close the modal after successful submission
    onClose();
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-gray-500 bg-opacity-75 fixed inset-0"></div>

        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full p-6 relative z-20">
          <h2 className="text-xl font-semibold mb-4">Add New Shift</h2>

          <div className="flex justify-between mb-4">
            {/* From Time */}
            <div>
              <label className="block text-sm font-medium text-gray-700">From</label>
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  min="1"
                  max="12"
                  value={fromHour}
                  onChange={(e) => setFromHour(e.target.value)}
                  className="mt-1 block w-20 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                :
                <input
                  type="number"
                  min="0"
                  max="59"
                  value={fromMinute}
                  onChange={(e) => setFromMinute(e.target.value)}
                  className="mt-1 block w-16 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <select
                  value={fromPeriod}
                  onChange={(e) => setFromPeriod(e.target.value)}
                  className="mt-1 block px-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            </div>

            {/* To Time */}
            <div>
              <label className="block text-sm font-medium text-gray-700">To</label>
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  min="1"
                  max="12"
                  value={toHour}
                  onChange={(e) => setToHour(e.target.value)}
                  className="mt-1 block w-20 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                :
                <input
                  type="number"
                  min="0"
                  max="59"
                  value={toMinute}
                  onChange={(e) => setToMinute(e.target.value)}
                  className="mt-1 block w-16 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <select
                  value={toPeriod}
                  onChange={(e) => setToPeriod(e.target.value)}
                  className="mt-1 block px-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            </div>
          </div>

          {/* Expected Efficiency */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Expected Efficiency</label>
            <input
              type="number"
              value={expectedEfficiency}
              onChange={(e) => setExpectedEfficiency(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter expected efficiency"
            />
          </div>

          {/* Add and Close Buttons */}
          <div className="flex justify-between">
            <button
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
              onClick={onClose}
            >
              Close
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleAddShift}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddShift;
