
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faCertificate, faCog, faFileLines } from '@fortawesome/free-solid-svg-icons';

import { NavLink } from 'react-router-dom';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useState } from 'react';



const Sidebar = () => {

    const [userLogged, setUserLogged]= useState<string>('')
    const [userLoggedProfile, setUserLoggedProfile]= useState<string>('')
    // let loggedUser= ;

    const fetchUserData= async ()=>{
        const fetchedData= await fetchAuthSession()
        // console.log(fetchedData.tokens?.idToken?.payload['email'])
        const loggedUserName= fetchedData.tokens?.idToken?.payload['cognito:username']
        const loggedUserProfile= fetchedData.tokens?.idToken?.payload['profile']
        
        setUserLogged(String(loggedUserName))
        setUserLoggedProfile(String(loggedUserProfile))
        return fetchedData;
    }
    fetchUserData()    
    
    return (
        <div className="w-2/12 border border-gray-200 bg-white h-screen flex flex-col">
            <div className="p-4 flex justify-center items-center flex-col">
                <div className="w-24 h-24 rounded-full bg-blue-200 mb-8 shadow-md"></div>

                <div className="ml-3 mb-4">
                    <p className="font-semibold text-lg ">{userLogged}</p>
                    <p className="text-xs text-center text-gray-500">{userLoggedProfile}</p>
                </div>
            </div>
            <nav className="flex-grow">
                <ul className="space-y-4 pt-4 pl-6">

                    <div>
                        <NavLink to="/">
                            {({ isActive }) => (
                                <div>
                                    <li
                                        className={`flex justify-center items-center pt-2 pb-2 rounded-s-xl ${isActive ? 'bg-gray-100 shadow-md text-black' : 'text-gray-700 hover:bg-gray-200 cursor-pointer'
                                            }`}
                                    >
                                        <FontAwesomeIcon
                                            icon={faFileLines}
                                            className={`mr-2 text-lg ${isActive ? 'text-blue-400' : 'text-blue-400'}`}
                                        />
                                        Sample
                                    </li>
                                </div>
                            )}
                        </NavLink>
                    </div>


                    

                    {/* <li className="flex justify-center items-center p-2 text-gray-700 hover:bg-gray-200 cursor-pointer rounded">
                        <FontAwesomeIcon icon={faCogs} className="mr-3 text-lg text-blue-500" /> Production
                    </li> */}

                    <div>
                        <NavLink to="/capacity">
                            {({ isActive }) => (
                                <div>
                                    <li
                                        className={`flex justify-center items-center  pt-2 pb-2 rounded-s-xl ${isActive ? 'bg-gray-100 shadow-md text-black' : 'text-gray-700 hover:bg-gray-200 cursor-pointer'
                                            }`}
                                    >
                                        <FontAwesomeIcon
                                            icon={faCertificate}
                                            className={`mr-3 text-lg ${isActive ? 'text-blue-400' : 'text-blue-400'}`}
                                        />
                                        Capacity
                                    </li>
                                </div>
                            )}
                        </NavLink>
                    </div>


                    <div>
                        <NavLink to="/production">
                            {({ isActive }) => (
                                <div>
                                    <li
                                        className={`flex justify-center items-center  pt-2 pb-2 rounded-s-xl ${isActive ? 'bg-gray-100 shadow-md text-black' : 'text-gray-700 hover:bg-gray-200 cursor-pointer'
                                            }`}
                                    >
                                        <FontAwesomeIcon
                                            icon={faCogs}
                                            className={`mr-3 text-lg ${isActive ? 'text-blue-400' : 'text-blue-400'}`}
                                        />
                                        Production
                                    </li>
                                </div>
                            )}
                        </NavLink>
                    </div>



                </ul>
            </nav>
            <div className="p-4 pb-16 ml-10 ">
                <button className="flex items-center justify-center pl-3 pr-8 pt-2 pb-2 text-gray-700 hover:bg-gray-100 hover:rounded-lg  rounde-lg">
                    <FontAwesomeIcon icon={faCog} className="mr-3 text-blue-500" /> Settings
                </button>
            </div>
        </div>
    );
};

export default Sidebar;


