import { store } from './redux/store';
import { fabricUpdated } from './redux/fabricSlice';
import { Fabric } from './App';


class WebSocketClient {
    private static instance: WebSocketClient;
    private ws: WebSocket | null = null;

    private constructor() {
        this.connect();
    }

    public static getInstance(): WebSocketClient {
        if (!WebSocketClient.instance) {
            WebSocketClient.instance = new WebSocketClient();
        }
        return WebSocketClient.instance;
    }

    private connect() {
        this.ws = new WebSocket('wss://0glqvu8kud.execute-api.us-east-1.amazonaws.com/dev');

        this.ws.onopen = () => {
            console.log('WebSocket connected');
            
        };

        this.ws.onmessage = (event: MessageEvent) => {
            const data = JSON.parse(event.data);

            if (data.message === "FABRIC_UPDATE") {
                console.log(data.body);
                store.dispatch(fabricUpdated(data.body));
            }
            if (data.message === "FABRICS_UPDATE") {
                if (Array.isArray(data.body)) {
                    data.body.forEach((fabric: Fabric) => {
                        store.dispatch(fabricUpdated(fabric));
                    });
                } else {
                    store.dispatch(fabricUpdated(data.body));  // Handle single fabric update as well
                }
            }
            if (data.message === "QUANTITY_UPDATE") {
                console.log(data.body);
                
                // if (Array.isArray(data.body)) {
                //     data.body.forEach((fabric: Fabric) => {
                //         store.dispatch(fabricUpdated(fabric));
                //     });
                // } else {
                //     store.dispatch(fabricUpdated(data.body));  // Handle single fabric update as well
                // }

            }
        };

        this.ws.onclose = () => {
            console.log('WebSocket disconnected, attempting to reconnect...');
            setTimeout(() => this.connect(), 5000);  // Reconnect after 5 seconds
        };

        this.ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            this.ws?.close();
        };
    }

    public disconnect() {
        if (this.ws) {
            this.ws.close();
        }
    }
}

// Initialize the WebSocket client when the application starts
WebSocketClient.getInstance();
