import React from 'react';

const DataNotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full space-y-6">
      <h1 className="text-4xl font-bold text-red-500 animate-bounce mb-4 flex items-center space-x-3">
        <span className="animate-spin-slow">⚠️</span>
        <span>Oops! Contact the Administrator</span>
        <span className="animate-spin-slow">⚠️</span>
      </h1>
      <p className="text-lg text-gray-500 animate-fade-in mt-2 flex items-center space-x-3">
        
        <span className="animate-fade-in-down">The requested data could not be found.</span>
        
        <span className="animate-spin-slow text-2xl">😕</span>
      </p>
      <p className="text-sm text-gray-400 animate-fade-in-up">
        Please double-check with the team and try again later! <span className="animate-wiggle">🙌</span>
      </p>
    </div>
  );
};

export default DataNotFound;
