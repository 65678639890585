import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderInterface } from "../App";

interface OrderListInterface {
    orders: OrderInterface[];
    currentOrder: OrderInterface | null;  // Add this line to include a currentOrder field
}

const initialState: OrderListInterface = {
    orders: [],
    currentOrder: null  // Initialize currentOrder as null
};

export const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        addOrders: (state, { payload }: PayloadAction<OrderInterface[]>) => {
            // Removes all existing orders and then adds new orders
            state.orders = payload;
        },
        deleteOrder: (state, {payload: {id}}) => {
            state.orders = state.orders.filter(order => order.id !== id);

        },
        editOrder: (state, action: PayloadAction<OrderInterface>) => {
            // Enhanced debugging to ensure payload is received correctly
            console.log("Received payload for update:", action.payload);
        
            // Find the index of the order to update
            const index = state.orders.findIndex(order => order.id === action.payload.id);
        
            if (index !== -1) {
                // Log found order details
                console.log("Current order details before update:", state.orders[index]);
        
                // Perform deep update to ensure immutability is maintained
                state.orders[index] = {
                    ...state.orders[index],
                    ...action.payload,
                    stage: {
                        ...state.orders[index].stage,
                        ...action.payload.stage
                    }
                };
        
                // Log updated order details
                console.log("Updated order details:", state.orders[index]);
            } else {
                // If no order is found with the given ID
                console.log("No order found with ID:", action.payload.id);
            }
        },
        setCurrentOrder: (state, action: PayloadAction<OrderInterface>) => {
            // Set the current order for detailed views
            state.currentOrder = action.payload;
        },
        updateOrderCAD: (state, action: PayloadAction<{ id: string; cad_avg: number }>) => {
            // Updates the CAD average for a specific order
            const { id, cad_avg } = action.payload;
            const order = state.orders.find(order => order.id === id);
            if (order && order.stage) {
                order.stage.cad_avg = cad_avg;
            }
            // Optionally update currentOrder directly if it is being viewed
            if (state.currentOrder && state.currentOrder.id === id) {
                state.currentOrder.stage = {...state.currentOrder.stage, cad_avg: cad_avg};
            }
        },
        
    }
});

// Exporting new action
export const { addOrders, deleteOrder, editOrder, updateOrderCAD, setCurrentOrder } = orderSlice.actions;

export default orderSlice.reducer;
