
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import UpperBar from './components/UpperBar';
import { Dashboard } from './pages/Dashboard';
import Order from './pages/Order';
import CustomAuthenticator from './components/CustomAuthenticator';
import Capacity from './pages/Capacity';

interface OrderQuantity {
  id: string;
  order_id: string;
  size: string;
  actual_qty: number;
}

export interface OrderInterface {
  id: string;
  approved_by?: string | null;
  created_at?: string | null;
  started_at?: string | null;
  stage?: {
    id?: string | null;
    stage_name?: number | undefined | null;
    started_at?: string | null;
    expected_at?: string | null;
    finished_at?: string | null;
    curr_state?: string | null;
    cad_avg?: number | undefined | null;
    man_avg?: number | undefined | null;
    grain?: string | null;
    width?: number | undefined | null;

  } | null;
  employee?: {
    name?: string | null;
  } | null;
  style: {
    style_no: string;
    style_name?: string | null;
    id?: string ;
    buyer?: {
      name?: string | null;
    } | null;
  };
  order_quantity: OrderQuantity[];
  
}


export interface Fabric {
  id?: string;
  crm_id?: number|undefined;
  crm_name: string|null;
  tenant_id: number|undefined;
  style_id: string|null;
  fabric_name: string|null;
  cad_avg: number|undefined;
  man_avg: number|undefined;
  grain: string|null;
  finished_width: number|undefined;
  man_width: number | undefined;
  created_at: string| null;
  updated_at: string| null;
}

const App: React.FC = () => {
  return (
    <CustomAuthenticator>
      <Router>
        <div className='flex-col'>
          <div className="flex h-screen flex-grow">
            <Sidebar />
            <div className="flex-grow overflow-auto p-4 pt-8 bg-gray-100">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/order/:id" element={<Order />} />
                <Route path="/capacity" element={<Capacity/>} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          </div>
        </div>
      </Router>
    </CustomAuthenticator>
  );
};

export default App;
