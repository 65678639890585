import { getCurrentUser } from "@aws-amplify/auth";
import SignIn from "./SignIn";
import { useEffect, useState, ReactNode } from "react";
import UpperBar from "./UpperBar";

interface CustomAuthenticatorProps {
  children: ReactNode;
}

const CustomAuthenticator: React.FC<CustomAuthenticatorProps>  = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      try {
        await getCurrentUser();
        setIsAuthenticated(true);
      } catch {
        setIsAuthenticated(false);
      }
    };
    checkUser();
  }, []);

  const handleSignIn = () => {
    setIsAuthenticated(true);
  };
  const handleSignOut= ()=>{
    setIsAuthenticated(false)
  }

  if (!isAuthenticated) {
    return <SignIn onSignIn={handleSignIn} />;
  }


  return (
    <div>
      {/* <Navbar onSignOut={handleSignOut}/> */}
      <UpperBar onSignOut={handleSignOut}/>
      {children}
    </div>
  );
};

export default CustomAuthenticator;
