import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import orderReducer from './order';
import fabricReducer from './fabricSlice';
import shiftsReducer from './shiftsSlice'; // Import the new shifts reducer
import productReducer from './productSlice';
import trimReducer from './trimSlice';

// Persist config
const persistConfig = {
  key: 'root',
  storage,
};

const persistedOrderReducer = persistReducer(persistConfig, orderReducer);

// Configure store with middleware allowing non-serializable values
export const store = configureStore({
  reducer: {
    orders: persistedOrderReducer,
    fabrics: fabricReducer,
    shifts: shiftsReducer, // Add the shifts reducer
    products: productReducer,
    trims: trimReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        // Ignore specific paths in actions or state
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        ignoredPaths: ['orders.someNonSerializablePath'],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
