// src/config.ts
interface Config {
    apiUrl: string;
}

// Example configuration, adjust as necessary for your environments
const devConfig: Config = {
    apiUrl: 'https://4qpi28k0zl.execute-api.ap-south-1.amazonaws.com/prod',
};



const config: Config = devConfig;

export default config;