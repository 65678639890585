import { createSlice, createAsyncThunk, PayloadAction, createAction } from '@reduxjs/toolkit';
import { Fabric } from '../App';
import config from '../config';

const apiUrl = config.apiUrl;
// Initial state type with the new interface
interface FabricState {
    fabrics: Fabric[];
    loading: boolean;
    error: string | null;
}

const initialState: FabricState = {
    fabrics: [],
    loading: false,
    error: null
};

// Define a new action for fabric updates
export const fabricUpdated = createAction<Fabric>('fabric/fabricUpdated');


// Async thunk for fetching fabric data
export const fetchFabricByStyle = createAsyncThunk<Fabric[], string, { rejectValue: string }>(
    'fabric/fetchFabricByStyle',
    async (styleId, { rejectWithValue }) => {
        try {

            const response = await fetch(`${apiUrl}/api/v1/fabric/style/${styleId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data: Fabric[] = await response.json();

            return data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);


// Fabric slice with type-safe actions
const fabricSlice = createSlice({
    name: 'fabric',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFabricByStyle.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFabricByStyle.fulfilled, (state, action: PayloadAction<Fabric[]>) => {
                state.fabrics = action.payload;
                state.loading = false;
            })
            // Add the new case for the fabric update
            .addCase(fabricUpdated, (state, action: PayloadAction<Fabric>) => {
                const updatedFabric = action.payload;
                const index = state.fabrics.findIndex(fabric => fabric.id === updatedFabric.id);

                if (index !== -1) {
                    // Update existing fabric
                    state.fabrics[index] = updatedFabric;
                } else {
                    // Add new fabric if it does not exist
                    state.fabrics.push(updatedFabric);
                }
            });
        // .addCase(fetchFabricByStyle.rejected, (state, action: PayloadAction<string>) => {
        //     state.loading = false;
        //     state.error = action.payload;
        // });
    }
});

export default fabricSlice.reducer;
