import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';

const apiUrl = config.apiUrl;

interface Status {
    value: number;
    status: string;
  }
  
  interface Comment {
    date: string;
    name: string;
    topic: string;
    message: string;
    profile: string;
    userSub: string;
  }
  
  interface Buyer {
    name: string;
  }
  
  interface Style {
    style_no: string;
    style_name: string;
    id: string;
    buyer: Buyer;
  }
  
  interface Employee {
    name: string | null;
  }
  
  interface Order {
    id: string;
    approved_by: string | null;
    created_at: string;
    started_at: string;
    style: Style;
    employee: Employee | null;
  }
  
  interface ProductDetails {
    id: string;
    name: string;
    description: string | null;
    units: string;
    skw: string;
    type: string | null;
    category: string | null;
  }
  
  interface ProductData {
    id: string;
    product_id: string;
    actual_quantity: number | null;
    expected_quantity: number;
    delivered_quantity: number | null;
    order_id: string;
    store_type: number;
    done: string | null;
    status: Status[] | null;
    comments: Comment[] | null;
    created_at: string;
    updated_at: string;
    order: Order;
    product: ProductDetails;
}

interface ProductState {
  products: ProductData[];
  loading: boolean;
  error: string | null;
}

const initialState: ProductState = {
  products: [],
  loading: false,
  error: null,
};

// Fetch products by item.id and storeType
export const fetchProductsByStore = createAsyncThunk(
  'products/fetchProductsByStore',
  async ({ itemId, storeType }: { itemId: string; storeType: number }) => {
    const response = await axios.get(`${apiUrl}/api/v1/store-order/order/${itemId}/store/${storeType}`);
    return response.data;
  }
);

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsByStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductsByStore.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(fetchProductsByStore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch products';
      });
  },
});

export default productSlice.reducer;






