import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';

const apiUrl = config.apiUrl;

// Define the Shift interface
interface Shift {
  id: string;
  department: number;
  shift: string;
  expected_avg: number;
}

// Define the state interface
interface ShiftsState {
  loading: boolean;
  error: string | null;
  shifts: Shift[];
  selectedDepartment: number;
}

// Initial state
const initialState: ShiftsState = {
  loading: false,
  error: null,
  shifts: [],
  selectedDepartment: 0,  // Default department (could be 0 or based on your app logic)
};

// Thunk to fetch shifts by department
export const fetchShifts = createAsyncThunk(
  'shifts/fetchShifts',
  async (departmentId: number, thunkAPI) => {
    try {
      const response = await axios.get(`${apiUrl}/api/v1/config/department/${departmentId}`);
      return response.data.map((shift: any) => ({
        id: shift.id,
        shift: shift.shift,
        expected_avg: shift.expected_avg,
      }));
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue('Failed to fetch shifts');
    }
  }
);

// Thunk to add a new shift
export const addShift = createAsyncThunk(
  'shifts/addShift',
  async (shiftData: { department: number; shift: string; expected_avg: number }, thunkAPI) => {
    try {
      const response = await axios.post(`${apiUrl}/api/v1/config`, shiftData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('Failed to add shift');
    }
  }
);

// Thunk to delete a shift
export const deleteShift = createAsyncThunk(
  'shifts/deleteShift',
  async (shiftId: string, thunkAPI) => {
    try {
      await axios.delete(`${apiUrl}/api/v1/config/${shiftId}`);
      return shiftId;  // Return the deleted shift's ID so we can remove it from state
    } catch (error) {
      return thunkAPI.rejectWithValue('Failed to delete shift');
    }
  }
);

// Thunk to update a shift
// export const updateShift = createAsyncThunk(
//   'shifts/updateShift',
//   async ({ id, updatedShift }: { id: string; updatedShift: { expected_avg: number } }, thunkAPI ) => {
//     try {
//       console.log(id);
//       console.log(updatedShift);
      
//       const response = await axios.put(`${apiUrl}/api/v1/config/${id}`, updatedShift);
//       return response.data;
//     } catch (error: any) {
//       return thunkAPI.rejectWithValue('Failed to update shift');
//     }
//   }
// );

export const updateShift = createAsyncThunk(
  'shifts/updateShift',
  async ({ id, updatedShift }: { id: string; updatedShift: { expected_avg: number } }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${apiUrl}/api/v1/config/${id}`, updatedShift);
      return response.data; // Ensure API returns the updated shift data
    } catch (error: any) {
      return rejectWithValue('Failed to update shift');
    }
  }
);



// Create a slice for shift management
const shiftsSlice = createSlice({
  name: 'shifts',
  initialState,
  reducers: {
    setSelectedDepartment: (state, action: PayloadAction<number>) => {
      state.selectedDepartment = action.payload;
    },
    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.shifts = [];
    },
  },
  extraReducers: (builder) => {
    // Handle fetching shifts
    builder.addCase(fetchShifts.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchShifts.fulfilled, (state, action: PayloadAction<Shift[]>) => {
      state.loading = false;
      state.shifts = action.payload;
    });
    builder.addCase(fetchShifts.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Handle adding a new shift
    builder.addCase(addShift.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addShift.fulfilled, (state, action: PayloadAction<Shift>) => {
      state.loading = false;
      state.shifts.push(action.payload);  // Add the newly created shift to the state
    });
    builder.addCase(addShift.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Handle deleting a shift
    builder.addCase(deleteShift.fulfilled, (state, action: PayloadAction<string>) => {
      state.shifts = state.shifts.filter(shift => shift.id !== action.payload);
    });
    builder.addCase(deleteShift.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });

    // Handle updating a shift
    builder.addCase(updateShift.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateShift.fulfilled, (state, action: PayloadAction<Shift>) => {
      state.loading = false;
    
      // Find and update the shift in the state
      const index = state.shifts.findIndex(shift => shift.id === action.payload.id);
      
      if (index !== -1) {
        // Update the shift in the array
        state.shifts[index] = action.payload;
      }
    });
    builder.addCase(updateShift.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

// Export actions and reducer
export const { setSelectedDepartment, resetState } = shiftsSlice.actions;
export default shiftsSlice.reducer;
