import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../redux/store'; // Import AppDispatch type
import { fetchShifts, setSelectedDepartment, deleteShift, updateShift } from '../redux/shiftsSlice';
import AddShift from '../components/AddShift';

const departments = [
  { id: 0, name: 'Select Department' },
  { id: 1, name: 'CUTTING' },
  { id: 2, name: 'SAMPLING' },
  { id: 3, name: 'FINISHING' },
];

const Capacity: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [addShiftOpen, setAddShiftOpen] = useState(false);
  const [editMode, setEditMode] = useState<string | null>(null); // To track which shift is being edited
  const [editValue, setEditValue] = useState<number | ''>(''); // To track the new efficiency value

  const { shifts, loading, error, selectedDepartment } = useSelector((state: any) => state.shifts);

  useEffect(() => {
    if (selectedDepartment !== 0) {
      dispatch(fetchShifts(selectedDepartment));
    }
  }, [selectedDepartment, dispatch]);

  const handleDepartmentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const departmentId = parseInt(e.target.value, 10);
    dispatch(setSelectedDepartment(departmentId));
  };

  const handleDeleteShift = (shiftId: string) => {
    dispatch(deleteShift(shiftId));
  };

  const handleEditClick = (shift: any) => {
    setEditMode(shift.id); // Set the shift ID as the edit mode
    setEditValue(shift.expected_avg); // Set the initial value for the input field (as number)
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEditValue(value === '' ? '' : parseFloat(value)); // Ensure that the value is a number
  };

  const handleSaveClick = (shiftId: string) => {
    if (editValue !== '') {
      // Dispatch the update action
      dispatch(updateShift({ id: shiftId, updatedShift: { expected_avg: editValue } }));
    }
    setEditMode(null); // Exit edit mode after saving
  };

  return (
    <div className="p-6 bg-gray-100 h-screen">
      {/* Department Selector and Add New Shift button */}
      <div className="flex justify-between items-center mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Department</label>
          <select
            value={selectedDepartment}
            onChange={handleDepartmentChange}
            className="p-2 border border-gray-300 rounded-md bg-white"
          >
            {departments.map((dept) => (
              <option key={dept.id} value={dept.id}>
                {dept.name}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={() => setAddShiftOpen(true)}
          className="bg-gray-300 text-black py-2 px-4 rounded hover:bg-gray-400"
        >
          Add New Shift
        </button>
      </div>

      {/* Loading state */}
      {loading && <p>Loading shifts...</p>}

      {/* Error state */}
      {error && <p className="text-red-500">{error}</p>}

      {/* Shifts Table */}
      {!loading && shifts.length > 0 && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="text-left">
                <th className="py-2 px-4 border-b font-semibold text-gray-700">Shift</th>
                <th className="py-2 px-4 border-b font-semibold text-gray-700">Expected Average</th>
                <th className="py-2 px-4 border-b font-semibold text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {shifts.map((shift: any) => (
                <tr key={shift.id} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b">{shift.shift}</td>
                  <td className="py-2 px-4 border-b">
                    {editMode === shift.id ? (
                      <input
                        type="number"
                        value={editValue}
                        onChange={handleEditChange}
                        className="border border-gray-300 p-1 rounded"
                      />
                    ) : (
                      shift.expected_avg
                    )}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {editMode === shift.id ? (
                      <button
                        className="text-green-500 mr-4 hover:underline"
                        onClick={() => handleSaveClick(shift.id)}
                      >
                        Save
                      </button>
                    ) : (
                      <>
                        <button
                          className="text-blue-500 mr-4 hover:underline"
                          onClick={() => handleEditClick(shift)}
                        >
                          Edit
                        </button>
                        <button
                          className="text-red-500 hover:underline"
                          onClick={() => handleDeleteShift(shift.id)}
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* No shifts available */}
      {!loading && shifts.length === 0 && selectedDepartment !== 0 && (
        <p>No shifts available for the selected department.</p>
      )}

      {/* AddShift component */}
      {selectedDepartment !== 0 && (
        <AddShift
          addShifts={addShiftOpen}
          department={selectedDepartment}
          onClose={() => setAddShiftOpen(false)}
        />
      )}
    </div>
  );
};

export default Capacity;
