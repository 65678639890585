import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface DataItem {
  date: string;
  cutting: number;
  sampling: number;
  finishing: number;
}

interface BarGraphProps {
  data: DataItem[];
}

const BarGraph: React.FC<BarGraphProps> = ({ data }) => {
    console.log("*******data for bargraph***");
    
    console.log(data);
    
  return (
    <div className='bg-gray-50 border border-gray-50 mt-5 shadow-md lg:w-8/12 md:w-11/12 sm:w-full xs:w-full rounded-3xl hover:shadow-lg hover:translate-y-3 transition-transform duration-600'>
      <ResponsiveContainer width="98%" height={430}>
        <BarChart data={data} margin={{ top: 20, right: 10, left: 10, bottom: 5 }}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          
          <Bar dataKey="cutting" fill="#98df8a" name="Cutting" radius={[10, 10, 0, 0]} />
          <Bar dataKey="sampling" fill="#aec7e8" name="Sampling" radius={[10, 10, 0, 0]} />
          <Bar dataKey="finishing" fill="#ff9896" name="Finishing" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarGraph;
