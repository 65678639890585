// import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { signOut } from '@aws-amplify/auth';
// import { signOut } from '@aws-amplify/auth';
interface UpperBarProps {
  onSignOut: () => void;
}

const UpperBar: React.FC<UpperBarProps> = ({onSignOut}) => {
  const handleSignOut = async () => {
    try {
      const res = await signOut();
      onSignOut()
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };
  return (
    <div className="w-full bg-white shadow-md border border-gray-100 flex justify-between items-center p-2 mb-1">
      <div className="text-lg font-bold">SK Exports</div>
      <div className="flex items-center">
        <button className="p-2 text-gray-700 hover:bg-gray-200 rounded-full">
          <FontAwesomeIcon icon={faBell} className='text-blue-500' />
        </button>
        <button
          onClick={handleSignOut}
          className="text-gray-800 px-4 py-2 ml-3 hover:bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 border"
        >
          sign out
        </button>
      </div>
    </div>
  );
};

export default UpperBar;


