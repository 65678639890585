/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    // "aws_cognito_identity_pool_id": "ap-south-1:099150da-0835-4f0c-bcc3-36e4b0db9063",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_cMzolyyxu",
    "aws_user_pools_web_client_id": "6s3p4ukrn488s33t5i30lp0c0f",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "NAME"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": []
};


export default awsmobile;
