import React, { lazy, useEffect, useState, Suspense } from 'react';
import { fetchAuthSession } from '@aws-amplify/auth';
import axios from 'axios';
import config from '../config';
import DataNotFound from '../components/DataNotFound';
import Loader from '../components/Loader';
import BarGraph from '../components/BarGraph';

const Modal = lazy(() => import('../components/Modal'));
const TableBar = lazy(() => import('../components/TableBar'));


const apiUrl = config.apiUrl;

interface DropdownOption {
  name: string;
  role: number;
}

interface Master {
  id: string;
  name: string;
}

// interface PriorityItem {
//     styleNumber: string;
//     currentPriority: number;
//     newPriority: number;
// }

interface PriorityItem {
  id: string;
  order_id: string;
  style_id: string;
  tenant_id: number;
  assigned_to: string;
  state_name: number;
  style_no: string;
  status: number;
  priority: number;
  start: string;
  end: string | null;
  remark: string | null;
  approve_date: string | null;
  approved_by: string | null;
  created_at: string;
  updated_at: string;
}

interface DataItem {
  date: string;
  cutting: number;
  sampling: number;
  finishing: number;
}


const formatDate = (date: Date) => date.toISOString().split('T')[0];


export const Dashboard = () => {
  const [userLogged, setUserLogged] = useState<string>('');
  const [userLoggedProfile, setUserLoggedProfile] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState<DropdownOption | null>(null);
  const [selectedMaster, setSelectedMaster] = useState<Master | null>(null);
  const [masters, setMaster] = useState<Master[]>([]);
  const [errors, setErrors] = useState<boolean>(false);
  const [dummyPriorityData, setDummyPriorityData] = useState<PriorityItem[]>([]);
  const [usersub, setUsersub] = useState<String>('');
  const [allOrders, setAllOrders] = useState<number>(0);
  const [pattern, setPattern] = useState<number>(0);
  const [cutting, setCutting] = useState<number>(0);
  const [barData, setBarData] = useState<DataItem[]>([]);



  const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  // Fetch worklog data from the new API

  useEffect(() => {
    // Get today's date and 7 days before
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 7);

    // Format dates as 'YYYY-MM-DD'
    const formattedStart = formatDate(startDate);
    const formattedEnd = formatDate(endDate);

    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/txn-worklog/start/${formattedStart}/end/${formattedEnd}`
        );

        console.log(formattedStart, formattedEnd);
        console.log("response", response.data);
        // Create an object to group data by date
        const groupedData: { [date: string]: DataItem } = {};

        response.data.forEach((item: any) => {
          const formattedDate = new Date(item.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

          // If the date is not yet in the groupedData, initialize it
          if (!groupedData[formattedDate]) {
            groupedData[formattedDate] = {
              date: formattedDate,
              cutting: 0,
              sampling: 0,
              finishing: 0
            };
          }

          // Assign the total_pieces to the correct department
          switch (item.department) {
            case 1:
              groupedData[formattedDate].sampling = item.total_pieces || 0;
              break;
            case 2:
              groupedData[formattedDate].cutting = item.total_pieces || 0;
              break;
            case 3:
              groupedData[formattedDate].finishing = item.total_pieces || 0;
              break;
            default:
              break;
          }
        });

        // Convert groupedData back into an array
        const transformedData = Object.values(groupedData);

        const sortedData = transformedData.sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });

        console.log(transformedData);

        setBarData(sortedData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  console.log("*********");
  console.log(barData);
  console.log("*********");

  const departments = [
    { name: 'pattern', role: 1 },
    { name: 'cutting', role: 2 },
    { name: 'sampling', role: 3 },
    { name: 'finishing', role: 4 },
    { name: 'email', role: 5 },
    { name: 'sample closing', role: 6 }
  ];





  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const fetchedData = await fetchAuthSession();
        console.log(fetchedData);

        const loggedUserName = fetchedData.tokens?.idToken?.payload['cognito:username']
        const loggedUserProfile = fetchedData.tokens?.idToken?.payload['profile'];
        const sub = fetchedData.userSub;

        setUsersub(String(sub));
        setUserLogged(String(loggedUserName));
        setUserLoggedProfile(String(loggedUserProfile)); // Simulating user role, adjust as needed

      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, []);


  useEffect(() => {
    const fetchOrder = async () => {

      try {
        const res = await fetch(`${apiUrl}/api/v1/order`);
        if (!res.ok) {
          console.error('Order API response not OK');
          setErrors(true); // set dataNotFound to true if API fails
          return;
        }
        const orderData = await res.json();
        console.log('Fetched Order Data:', orderData);
        setAllOrders(orderData.length);
        setErrors(false); // reset the error state if data is successfully fetched
      } catch (error) {
        console.error('Error fetching order data:', error);
        setErrors(true); // set dataNotFound to true if an error occurs
      }
    };
    fetchOrder();
  }, []);

  console.log((errors));



  useEffect(() => {
    const fetchPattern = async () => {

      try {
        const res = await fetch(`${apiUrl}/api/v1/state/internal/1/ALL`);
        if (res.ok) {
          const patterndata = await res.json();

          setPattern(patterndata.length);
        }
        else {
          return;
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchPattern();
  }, [])


  useEffect(() => {
    const fetchCutting = async () => {

      try {
        const res = await fetch(`${apiUrl}/api/v1/state/internal/2/ALL`);
        if (res.ok) {
          const cuttingData = await res.json();

          setCutting(cuttingData.length);
        }
        else {
          return;
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchCutting();
  }, [])

  console.log(pattern, cutting);


  const handleDepartmentSelect = async (department: DropdownOption) => {
    setSelectedDepartment(department);
    console.log(`Selected Department role : ${department.role}`);
    const response = await fetch(`${apiUrl}/api/v1/employee/role/${department.name}`);
    if (!response.ok) {
      setErrors(true);
      return (
        // <Suspense fallback={<div>Loading...</div>}>
        <DataNotFound />
        // </Suspense>
      );
    }

    const data = await response.json();
    console.log(data);

    setMaster(data);
  };



  const handleMasterSelect = (master: Master) => {
    setSelectedMaster(master);
    console.log('Selected Master:', master);
  };

  useEffect(() => {
    if (selectedDepartment && selectedMaster) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${apiUrl}/api/v1/state/internal/${selectedDepartment.role}/${selectedMaster.id}`);
          if (!response.ok) {
            setErrors(true);
            return (
              // <Suspense fallback={<div>Loading...</div>}>
              <DataNotFound />
              // </Suspense>
            );
          }
          const result = await response.json();
          setDummyPriorityData(result)
          console.log('API Response:', result);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [selectedDepartment, selectedMaster]);


  if (errors) {
    return <DataNotFound />
  }




  console.log(`dummyPriorityData: ${dummyPriorityData}`);




  return (
    <div className='pl-3 pr-3 flex flex-col space-y-6'>

      <div className='flex flex-grow justify-center border-2 rounded-lg p-2 pl-3 pr-3 border-gray-300'>
        {!errors && (
          <h2 className='text-2xl font-semibold'>Overview</h2>
        )}
      </div>
      <BarGraph data={barData} />

      <Suspense fallback={<div></div>}>
        {userLoggedProfile === 'merchant' && !errors && (
          <button
            onClick={() => setIsModalOpen(true)}
            className="mt-4 w-1/5 bg-cyan-600 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded"
          >
            Set Priority
          </button>
        )}
      </Suspense>

      <Suspense fallback={<div>Loading Modal...</div>}>
        {isModalOpen && !errors && (
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            departments={departments}
            onDepartmentSelect={handleDepartmentSelect}
            masters={masters}
            onMasterSelect={handleMasterSelect}
            priorityData={dummyPriorityData}
            userSub={usersub}
            profile={userLoggedProfile}
            name={userLogged}
          />
        )}
      </Suspense>

      <Suspense fallback={<div>Loading Table...</div>}>
        <TableBar allOrders={allOrders} pattern={pattern} cutting={cutting} />
      </Suspense>
    </div>
  );
};
